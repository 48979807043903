import { useState } from "react";

const PopupWinner = ({ show, ws, onClick }) => {
  const [passthrow, setPassthrow] = useState(false);

  const found = ws.alreadyFound.length;
  const total = ws.wordList.length;

  const onPassthrow = (e) => {
    e.preventDefault();
    if (e.target.classList.contains("popup")) {
      setPassthrow(!passthrow);
    }
  };

  const onClickInternal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  const awardMachine = () => {
    if (found >= 12) {
      return { label: "PLATINA", key: "platinum" };
    }
    if (found >= 10) {
      return { label: "OURO", key: "gold" };
    }
    if (found >= 6) {
      return { label: "PRATA", key: "silver" };
    }
    if (found >= 1) {
      return { label: "BRONZE", key: "bronze" };
    }
    return { label: "", key: "hide" };
  };

  return (
    <div
      className={
        "popup" + (show ? " --show" : "") + (passthrow ? " --passthrow" : "")
      }
      onClick={onPassthrow}
    >
      <div className="popup__wrapper-content">
        <div className="popup__content" onClick={onClickInternal}>
          <div className="popup__header">
            <img src="/logo.png" alt="" />
          </div>
          <div className="popup__main">
            {(found > 0 && <h1>Parabéns!</h1>) || <h1>Fim do tempo!</h1>}

            <div className={"award" + (" --" + awardMachine().key)}>
              <h2>Prémio</h2>
              <h1>{awardMachine().label}</h1>
            </div>

            <p>
              <b>{found}</b> de <b>{total}</b> palavras encontradas!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupWinner;
