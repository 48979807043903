const CountDown = ({ time, onClick }) => {
  //   const formattedTime = time < 10 ? `0${time}` : time;

  // format as minutes and seconds
  let minutes = Math.floor(time / 60);
  let seconds = time % 60;
  let formattedTime = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;

  return (
    <div className="countdown" onClick={onClick}>
      {formattedTime}
      <span>s</span>
    </div>
  );
};

export default CountDown;
