const PopupStart = ({ show, onClick }) => {
  const onClickInternal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={"popup2 --delay" + (show ? " --show" : "")}>
      <div className="popup2__wrapper-content">
        <div className="popup2__content" onClick={onClickInternal}>
          <div className="popup2__header">
            <img src="/logo.png" alt="" />
          </div>
          <div className="popup2__main">
            {/* <h1>Bem-vindo!</h1> */}
            <h1>Toque aqui para começar!</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupStart;
