import React from "react";

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: false,
      show: false,
    };

    // this.mouseOver = this.mouseOver.bind(this);
    // this.mouseOut = this.mouseOut.bind(this);
  }

  //   mouseOver(evt) {
  //     // this.setState({
  //     //   highlight: true,
  //     // });
  //   }

  static getDerivedStateFromProps(props, state) {
    if (state.highlight && !props.hasSelectionStarted()) {
      return {
        highlight: false,
      };
    }
    return null;
  }

  //   mouseOut(evt) {
  //     let id = evt.target.id;
  //     let startBox = this.props.hasSelectionStarted();
  //     // let highlight = false;
  //     // if (startBox && startBox.id === id) {
  //     //   highlight = true;
  //     // }
  //     // this.setState({
  //     //   highlight: highlight,
  //     // });
  //   }

  onJoinEvents(evt) {
    this.mouseOut(evt);
    this.props.selectionEnd(evt);
  }

  onMouseDown(evt) {
    const target = evt.target.closest(".cell");
    this.props.selectionStart(target, evt);
  }

  onMouseUp(evt) {
    const target = evt.target.closest(".cell");
    this.props.selectionEnd(target, evt);
  }

  onTouchStart(evt) {
    const touch = evt.touches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );
    const target = targetElement.closest(".cell");
    this.props.selectionStart(target, evt);
  }

  onTouchEnd(evt) {
    const touch = evt.changedTouches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );
    const target = targetElement.closest(".cell");
    this.props.selectionEnd(target, evt);
  }

  render() {
    let cell = this.props.cell;
    let id = cell.id;
    let currClass =
      "cell" +
      (cell.used ? " use" : "") +
      (cell.used || this.state.highlight ? " highlight" : "") +
      (cell.show && !(cell.used || this.state.highlight) ? " show" : "");

    return (
      <div
        className={currClass}
        id={id}
        onMouseDown={this.onMouseDown.bind(this)}
        onMouseUp={this.onMouseUp.bind(this)}
        // onMouseOut={this.mouseOut}
        // onMouseOver={this.mouseOver}
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
        // onTouchMove={this.mouseOver}
        style={{ "--base-color": cell.color || "" }}
      >
        <div className="cell__inner">{cell.letter}</div>
      </div>
    );
  }
}

export default Cell;
