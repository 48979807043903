import WordSearch from './Game/WordSearch';
import './Styles/App.css';

function App() {
    return (
        <WordSearch />
    );
}

export default App;
