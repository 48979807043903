import React from "react";
import Row from "./Row";
import CountDown from "./CountDown";
import HighlighLine from "./HighlighLine";

class Grid extends React.Component {
  constructor(props) {
    super(props);

    this.ws = props.ws;
    this.ws.startBox = null;
    this.ws.endBox = null;

    this.selectionStart = this.selectionStart.bind(this);
    this.selectionEnd = this.selectionEnd.bind(this);
    this.hasSelectionStarted = this.hasSelectionStarted.bind(this);
    this.state = {
      ws: this.ws,
      selectionStart: false,
      selectionStartCoords: { x: 0, y: 0 },
      selectionCurrentCoords: { x: 0, y: 0 },
    };

    this.timeout = null;
  }

  selectionStart(target, evt) {
    // const target = evt.target.closest(".cell");
    // if (!target) return;

    let id = target.id;
    this.ws.startBox = this.ws.getBoxById(id);

    let rect = target.getBoundingClientRect();
    let x = rect.x + rect.width / 2;
    let y = rect.y + rect.height / 2;

    this.setState({
      ...this.state,
      selectionStart: true,
      selectionStartCoords: { x: x, y: y },
      selectionCurrentCoords: { x: x, y: y },
    });

    console.log("selectionStart");

    return false;
  }

  selectionEnd(target, evt) {
    // const target = evt.target.closest(".cell");
    if (!target) return;

    if (this.ws.startBox != null) {
      let id = target.id;
      this.ws.endBox = this.ws.getBoxById(id);
    }

    if (this.ws.startBox && this.ws.endBox) {
      console.log("selectionEnd");

      let strObj = this.ws.getStringBetweenPoints(
        this.ws.startBox,
        this.ws.endBox
      );
      let str = strObj.str;
      let obj = this.ws.TestString(str);
      if (obj.match && !obj.found) {
        this.ws.alreadyFound.push(str);
        this.ws.wordList.forEach((item) => {
          //   console.log(
          //     str.split("").reverse().join("").toLowerCase(),
          //     item.text.toLowerCase()
          //   );
          if (
            item.text.toLowerCase() === str.toLowerCase() ||
            str.split("").reverse().join("").toLowerCase() ===
              item.text.toLowerCase()
          ) {
            item.found = true;
            // console.log(item);
          }
        });
        strObj.ids.forEach((item) => {
          let [i, j] = item;
          this.ws.gridArr[i][j].used = true;
        });

        // for(let i = 0; i < this.ws.gridArr.length; i++) {
        //   for(let j = 0; j < this.ws.gridArr[i].length; j++) {
        //     if(strObj.ids.indexOf(this.ws.gridArr[i][j].id) !== -1) {
        //       this.ws.gridArr[i][j].used = true;
        //     }
        //   }
        // }
        // this.forceUpdate();
      }

      this.ws.startBox = null;
      this.ws.endBox = null;
      this.setState({
        ...this.state,
        ws: this.ws,
        selectionStart: false,
      });

      if (this.ws.wordList.every((item) => item.found)) {
        clearInterval(this.timeout);
        this.props.onComplete();
      }
    }
  }

  //   mouseOver(evt) {
  //     if(this.ws.startBox !== null) {
  //       let tempEndBox;
  //       let id = evt.target.id;
  //       tempEndBox = this.ws.getBoxById(id);
  //       let dir = this.ws.getDirection(this.ws.startBox,  tempEndBox);
  //       //highLightBoxes(this.ws.startBox, tempEndBox, dir);
  //     }
  //   }

  hasSelectionStarted() {
    return this.ws.startBox;
  }

  start() {
    if (this.timeout) clearInterval(this.timeout);

    this.ws.timeLeft = this.ws.timeLeftTotal;
    this.setState({
      ...this.state,
      ws: this.ws,
    });

    this.timeout = setInterval(() => {
      if (this.ws.timeLeft > 0) {
        this.ws.timeLeft--;
        this.setState({
          ...this.state,
          ws: this.ws,
        });
      } else {
        clearInterval(this.timeout);
        this.props.onTimeout();
      }
      //   console.log(this.ws.timeLeft);
    }, 1000);
  }

  stop() {
    clearInterval(this.timeout);
    this.ws.timeLeft = this.ws.timeLeftTotal;
    this.setState({
      ...this.state,
      selectionStart: false,
      selectionStartCoords: { x: 0, y: 0 },
      selectionCurrentCoords: { x: 0, y: 0 },
    });
  }

  mouseMove(target) {
    if (!this.state.selectionStart) return;

    // element is a cell
    // const target = evt.target.closest(".cell");
    if (target) {
      let rect = target.getBoundingClientRect();
      let x = rect.x + rect.width / 2;
      let y = rect.y + rect.height / 2;

      this.setState({
        ...this.state,
        selectionCurrentCoords: { x: x, y: y },
      });
    }
  }

  onMouseMoveEvent(evt) {
    // evt.preventDefault();
    const target = evt.target.closest(".cell");
    this.mouseMove(target);
  }

  onTouchEvent(evt) {
    // evt.preventDefault();
    const touch = evt.touches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );
    const target = targetElement.closest(".cell");
    this.mouseMove(target);
  }

  render() {
    let gridStyle = {
      width: 54 * this.props.ws.gridSize + "px",
      height: 54 * this.props.ws.gridSize + "px",
    };
    let gridArr = this.props.ws.gridArr.slice();
    let wordList = this.props.ws.wordList.slice();
    let toastVisible =
      this.props.ws.wordList.length === this.props.ws.alreadyFound.length;

    return (
      <>
        {this.state.selectionStart && (
          <HighlighLine
            start={this.state.selectionStartCoords}
            end={this.state.selectionCurrentCoords}
          />
        )}

        <div id="root-grid">
          <div className="side-panel">
            <img className="logo" src="/logo.png" alt="" />
            <CountDown
              time={this.props.ws.timeLeft}
              onClick={this.props.onResetAction}
            />
            <div className="word-list">
              <ul>
                {wordList.map((item, i) => {
                  return (
                    <li key={i} className={item.found ? "select" : ""}>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="content">
            <div
              className="grid"
              style={gridStyle}
              onTouchMove={this.onTouchEvent.bind(this)}
              onMouseMove={this.onMouseMoveEvent.bind(this)}
            >
              {gridArr.map((row, i) => {
                return (
                  <Row
                    row={row}
                    rowIndex={i}
                    key={i}
                    selectionStart={this.selectionStart}
                    selectionEnd={this.selectionEnd}
                    hasSelectionStarted={this.hasSelectionStarted}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Grid;
