import { useEffect, useRef } from "react";

const HighlighLine = ({ start, end }) => {
  return (
    <svg className="highligh-line">
      {/* <div style={{ top: start.x, left: start.y }}></div>
      <div style={{ top: end.x, left: end.y }}></div> */}
      <line
        x1={start.x}
        y1={start.y}
        x2={end.x}
        y2={end.y}
        stroke="red"
        strokeWidth="2"></line>
    </svg>
  );
};

export default HighlighLine;
