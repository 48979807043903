import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// function adjustViewport() {
//     var gameWidth = 1920;
//     var gameHeight = 1080;
//     var windowWidth = window.innerWidth;
//     var windowHeight = window.innerHeight;

//     var scaleWidth = windowWidth / gameWidth;
//     var scaleHeight = windowHeight / gameHeight;
//     var scale = Math.min(scaleWidth, scaleHeight);

//     var viewportContent = 'width=' + gameWidth + ', initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale;
//     document.querySelector('meta[name="viewport"]').setAttribute('content', viewportContent);
// }
// window.addEventListener('resize', adjustViewport);
// adjustViewport();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
