const completeList = [
    "Água",
    "Agricultura",
    "Empresas",
    "Tesouraria",
    "Investimento",
    "Ribatejo",
    "Cereal",
    "Pecuária",
    "Sustentável",
    "Santarém",
    "Regadio",
    "Azeite",
    "Laranjeira",
    "Vinho",
];

const completeColorsList = [
    '#FF5733', '#673ab7', '#3357FF', '#F1C40F', '#9B59B6',
    '#E74C3C', '#9E9E9E', '#795548', '#3498DB', '#8BC34A',
    '#34495E', '#16A085', '#27AE60', '#2980B9'
];

function getRandomWords() {
    let lst = [];
    for (var i = 0; i < 14; i++) {
        let currItem = completeList[Math.floor(Math.random() * completeList.length)];
        if (lst.indexOf(currItem) === -1) {
            lst.push(currItem)
        } else {
            i--;
        }
    }
    return completeList; lst.sort((a, b) => { return b.length - a.length });
}

function getRandomColor(idx = null) {
    if (idx === null) {
        idx = Math.floor(Math.random() * completeColorsList.length);
    }
    return completeColorsList[idx % completeColorsList.length];
}

export { getRandomWords, getRandomColor, completeList };