import React from "react";
import Grid from "./Grid";
import WSGenerator from "../Utils/WSGenerator";
import PopupWinner from "./PopupWinner";
import PopupStart from "./PopupStart";

class WordSearch extends React.Component {
  constructor(props) {
    super(props);
    this.debug = false;
    this.state = {
      showPopup: false,
      showPopupWinner: false,
    };
    this.wsGenerator = new WSGenerator();
    this.wsGenerator.setGridSize();
    this.wsGenerator.initGrid();
    this.wsGenerator.populateUnusedBoxes();
    this.wsGenerator.timeLeft = this.wsGenerator.timeLeftTotal;
    this.gridRef = React.createRef();

    // when press r reset the grid
    window.addEventListener("keydown", (e) => {
      if (e.key === "r") {
        this.resetGrid();
      }

      if (e.key === "s") {
        this.toggleDebug();
      }
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        ...this.state,
        showPopup: true,
      });
    }, 300);
  }

  resetGrid() {
    this.wsGenerator.initGrid();
    this.wsGenerator.populateUnusedBoxes();
    this.wsGenerator.wordList.forEach((item) => {
      item.found = false;
    });
    this.wsGenerator.alreadyFound = [];
    this.debug = false;

    this.forceUpdate(() => {
      //   if (this.gridRef.current) {
      //     this.gridRef.current.start();
      //   }
    });
  }

  start() {
    if (this.gridRef.current) {
      this.gridRef.current.start();
    }

    this.resetGrid();

    this.setState({
      ...this.state,
      showPopup: false,
    });
  }

  toggleDebug() {
    this.wsGenerator.showX(!this.debug);
    this.forceUpdate();
    this.debug = !this.debug;
  }

  onComplete() {
    this.setState({
      ...this.state,
      showPopupWinner: true,
    });
  }

  onTimeout() {
    this.wsGenerator.showX(true);
    this.forceUpdate();

    this.setState({
      ...this.state,
      showPopupWinner: true,
    });
  }

  onReset() {
    this.setState({
      ...this.state,
      showPopupWinner: false,
      showPopup: true,
    });
    if (this.gridRef.current) {
      this.gridRef.current.stop();
    }
    this.resetGrid();
  }

  render() {
    return (
      <>
        <Grid
          ref={this.gridRef}
          ws={this.wsGenerator}
          onComplete={this.onComplete.bind(this)}
          onTimeout={this.onTimeout.bind(this)}
          onResetAction={this.onReset.bind(this)}
        />

        <PopupWinner
          show={this.state.showPopupWinner}
          ws={this.wsGenerator}
          onClick={this.onReset.bind(this)}
        />
        <PopupStart
          show={this.state.showPopup}
          onClick={this.start.bind(this)}
        />
      </>
    );
  }
}

export default WordSearch;
